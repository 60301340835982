import Image from "next/image";

import { IFormatedAsset } from "../../../../@types";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { useFetch } from "../../../../hooks/useFetch";
import { useGame } from "../../../../hooks/useGame";
import useWax, { IMutateOptions } from "../../../../hooks/useWax";
import { SWRCacheKeyGetters, atomicFetcher } from "../../../../services/swr";
import {
  cleanAndCapitalize,
  findAttrCaps,
  getAttrsToHideByType,
  getAttrsToShow,
  getMaxLevel,
  isActivated,
  manaAtrsFirst,
} from "../../../../utils";
import {
  boostCardSchema,
  cannonsCardSchema,
  petsCardSchema,
  typeBySchema,
} from "../../../../utils/constants";
import { BattleAcessoriesButtons } from "../CardDetails/BattleAcessoriesButtons";
import { PetsButtons } from "../CardDetails/PetsButtons";

interface Props {
  item: IFormatedAsset;
  type: "Pets Card" | "Cannons Cards" | "Boost Cards";
  userBalances: {
    zombieTokenBalance: any;
    potions: { trainPotions: any; levelUpPotions: any };
    templates: { trainPotionTemplate: any; levelUpPotionTemplate: any };
    tools: { upgradeTools: any };
  };
}

export const InventoryItem = ({ item, type, userBalances }: Props) => {
  const { currentUser } = useCurrentUser();
  const { userInventory, petsData } = useFetch();
  const {
    contractActivateForPVPAction,
    contractRepairNFT,
    contractLevelUpAcessory,
  } = useWax();
  const { setTrainSelectedItem, setLevelUpSelectedItem } = useGame();

  const { levelCap, boostsLevelCap, cannonsLevelCap } = useFetch();

  const itemLevelCap: any = findAttrCaps(item, [
    ...levelCap,
    ...boostsLevelCap,
    ...cannonsLevelCap,
  ]);

  const itemPetData = petsData?.find(
    (nft: { assetId: string }) => nft.assetId === item.assetId.toString(),
  );

  const maxLevel = getMaxLevel(item, levelCap);

  const activate = isActivated(item, type);

  const mutateOptions: IMutateOptions[] = [
    {
      key: SWRCacheKeyGetters.getUserInventory(currentUser),
      currentData: userInventory,
      fetcher: atomicFetcher,
    },
  ];

  const handleActionsButton = (type: any) => {
    const cardType = typeBySchema[item.schema];
    switch (type) {
      case "activation":
        return contractActivateForPVPAction(
          [item.assetId as string],
          mutateOptions,
        );

      case "train":
        return setTrainSelectedItem(item);

      case "levelup":
        return setLevelUpSelectedItem(item);

      case "repair":
        return contractRepairNFT([item.assetId as string], mutateOptions);

      case "levelup-tool":
        return contractLevelUpAcessory(
          item.assetId as string,
          userBalances.tools.upgradeTools[0],
          cardType,
          item.data.level,
          mutateOptions,
        );

      default:
        return null;
    }
  };

  const renderLevelReqIcon = (atr: string) => {
    const verify: boolean = +atr >= +itemLevelCap.max_attr_value;

    if (verify)
      return (
        <div className="flex h-[16px] w-[16px] items-center justify-center rounded-full bg-black">
          <div className={"text-2xl font-bold text-green-700"}>
            <>&#10004;</>
          </div>
        </div>
      );
    return null;
  };

  const attributes = manaAtrsFirst(Object.entries(item?.attributes));
  const attributesToHide = getAttrsToHideByType(type);
  const attributesToShow = getAttrsToShow(type);

  return (
    <div>
      <div className="flip-card group h-[300px] w-[200px] bg-blue-700 text-center">
        <div className="flip-card-inner">
          <div
            className={`flip-card-back peer flex ${
              !activate ? "!items-center !justify-end " : "!justify-around"
            } bg-blue-700 text-center`}
          >
            {activate && (
              <div className="mt-4">
                <p>Attributes</p>
              </div>
            )}
            <div className="flex flex-col items-center justify-center">
              {activate ? (
                attributes.map((item, _) => {
                  if (attributesToShow.includes(item[0]))
                    return (
                      <div
                        key={_}
                        className="flex items-center justify-center gap-2 text-sm"
                      >
                        <span className="w-[100px] text-center">
                          {cleanAndCapitalize(item[0] as string)}:
                        </span>
                        <div className="flex w-[50px] flex-row flex-nowrap gap-2 text-center">
                          <span className="flex w-[50px] flex-row flex-nowrap gap-2 text-center">
                            {item[1] as string}
                            {![
                              "hp",
                              "mana",
                              "mana_regen",
                              "level",
                              "wins",
                            ].includes(item[0])
                              ? itemLevelCap?.max_attr_value
                                ? `/${itemLevelCap?.max_attr_value}`
                                : null
                              : null}
                            {itemLevelCap?.max_attr_value && (
                              <span className="flex items-center justify-center">
                                {!["hp", "mana", "mana_regen"].includes(item[0])
                                  ? renderLevelReqIcon(item[0])
                                  : null}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    );

                  return null;
                })
              ) : (
                <div className="flex h-[175px] flex-col">
                  <p className="p-2">You need to activate this card for PvP</p>
                </div>
              )}
            </div>

            {itemPetData && (
              <div className="flex items-center justify-center gap-2 text-sm">
                <span className="text-[12px]">Ranked Matches:</span>{" "}
                <span className="text-[12px]">
                  {itemPetData?.gamesPlayed ?? 0}
                </span>
              </div>
            )}

            {item.schema === petsCardSchema && (
              <PetsButtons
                item={item}
                activate={activate}
                handlePetsButton={handleActionsButton}
                userBalance={userBalances}
                levelCap={itemLevelCap}
              />
            )}
            {(item.schema === cannonsCardSchema ||
              item.schema === boostCardSchema) && (
              <BattleAcessoriesButtons
                item={item}
                handleAcessoriesButton={handleActionsButton}
                userBalance={userBalances}
              />
            )}
          </div>
          <div className="flip-card-front items-between flex flex-col peer-hover:hidden">
            <div className="items-around mt-4 flex w-full flex-col justify-around">
              <div>
                <p>{item?.attributes?.name}</p>
              </div>
              <div>
                <small>{item?.assetId}</small>
              </div>
            </div>
            <div className="flex flex-1 items-center justify-center transition-all duration-100 ">
              {item?.img ? (
                <Image width={128} height={128} src={`${item?.img}`} />
              ) : (
                <div className="flip-card-front h-[256px] w-[128px] bg-blue-700 text-center">
                  Img Missing
                </div>
              )}
            </div>
            {activate && (
              <div className="items-around mb-4 flex w-full justify-around">
                {item.schema === petsCardSchema && (
                  <div>
                    Level: {item?.attributes?.level || 1}
                    {maxLevel > 0 ? "/" + maxLevel : null}
                  </div>
                )}

                {item.schema === petsCardSchema && item?.attributes?.hp && (
                  <div>HP: {item?.attributes?.hp || 0}</div>
                )}
                {item?.attributes?.durability ? (
                  <div>
                    Dur: {item?.attributes?.durability || 0}/
                    {itemLevelCap?.durability || null}
                  </div>
                ) : null}
                {item.schema !== petsCardSchema && (
                  <div>Level: {item.attributes?.level || 1}</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
